<template lang="pug">
  div.contactLens
    assembly-card-page(:title='"商品详情"' @back='back')
    .auto(:style='{ "--tableHeight": tableHeight }')
      //- search-bar(:row='3' ref='searchBar')
      //-   template(v-slot:content)
      //-     el-form(:size='size' :inline="true" :model="userForm" label-width="100px" class="search")
      //-       el-form-item(label="零售商")
      //-         el-select(:size='size' v-model='userForm.emailEgRetailerId' clearable)
      //-           el-option(
      //-             v-for='item in selectList'
      //-             :key='item.sysCiId'
      //-             :value='item.sysCiId'
      //-             :label='item.sysCiFullName'
      //-           )
      //-       el-form-item(label="厂家订货号")
      //-         el-input(v-model='userForm.emailEgOrderNum' clearable @keyup.enter.native="sendMsg")

      //-   template(v-slot:footer)
      //-     el-button(:size='size' type='primary' icon='el-icon-search' @click='getList') 查询
      //-     el-button(:size='size' icon='el-icon-delete' @click='reset') 清空
      ps-table.mt10.auto_height(
        height='100%'
        v-loading='tableLoading'
        ref='psTable'
        :header-cell-style="headerCellStyle"
        :size="size"
        :data='tableData'
        :columns='columns'
        :columns-props="columnsProps"
      )
        template(#解析状态="{ row = {} }")
          span(:style="{ color: !row.emailFlStatus ? 'green' : 'red' }") {{ row.emailFlStatus === 0 ?'成功':'失败' }}
        template(#操作="{ row ={} }")
          el-button(type='text' :size='size' icon='el-icon-position' @click='reSend(row.emailFlEmailRoleId)' v-show='row.emailFlStatus') 二次解析
    pagination(:total='total' :page-size='pageSize' :current-page='page' @size-change='changeSize' @current-change='changePage')
</template>

<script>
import pagination from "@/components/assembly-pagination/index"
import { configMixin } from "../../emailGoods/configMixin" // 配置类混入
import assemblyCardPage from "@/components/assembly-card-page"
export default {
   name: "contactLens",

   components: {
      pagination,
      assemblyCardPage
   },

   props: {
      row: Object,
   },

   mixins: [configMixin],
   watch: {
      row: {
         handler(newval, oldval) {
            if (newval) {
               this.userForm.emailEgOrderNum = newval.emailFlOrderNum;
               this.userForm.emailEgRetailerId = newval.emailFlRetailerId;
               this.getList()


            }

         },
         deep: true,
         immediate: true
      }

   },

   data() {
      return {
         tableLoading: false,
         total: 0,
         page: 1,
         pageSize: 10,
         userForm: {
            emailEgRetailerId: "",
            emailEgOrderNum: ""
         },
         selectList: [],
      }
   },

   methods: {
      // 二次解析
      reSend(id) {
         this.$server.reSendEmail({ failLogId: id }).then(res => {
            if (res.ok) {
               this.$message.success("二次解析成功")
            } else {
               this.$message.error(res.msg)
            }
         })
      },


      reset() {
         this.userForm = {}
         this.page = 1
         this.getList()
      },

      getGHSList() {
         this.$server.getGHSList({ sysCiType: 3 }).then(res => {
            this.selectList = res.data
         })
      },

      changeSize(val) {
         this.page = 1
         this.pageSize = val
         this.getList()
      },

      changePage(val) {
         this.page = val
         this.getList()
      },

      getList() {
         this.tableLoading = true
         const { page, pageSize, userForm } = this
         this.$server
            .getProductEmail({ page, pageSize, ...userForm })
            .then(res => {
               this.tableData = res.data.records
               this.total = res.data.total
               this.tableLoading = false
            })
            .catch(_ => {
               this.tableLoading = true
            })
      },
      sendMsg() {
         this.getList()
      },
      back() {
         this.$emit("back")
      },
   },

   computed: {
      tableHeight() {
         let init = 170
         const { fastNav, isHeader } = this.$store.state
         fastNav === 1 ? init -= 31 : 0
         isHeader === 1 ? init -= 60 : 0
         init = init + 80
         console.log(init);
         return init + 'px'
      },
   },

   mounted() {
      this.pageSize = this.$store.state.initPageSize ? this.$store.state.initPageSize : 10;
      this.getList()
      // this.getGHSList()
   },
}
</script>
<style lang="scss">
.search {
   //搜索展开收起按钮位置设置
   .searchOpenBtn {
      float: right;
   }

   //表单每行底部外边距设置
   .el-form-item {
      margin-bottom: 10px;
   }

   .el-select {
      width: 200px;
   }

   .el-input {
      width: 200px;
   }

   //分割线外边距设置
   .el-divider--horizontal {
      margin: 0 0 10px 0;
   }
}

.el-dialog {
   .el-select {
      width: 100%;
   }
}
</style>
<style scoped>
.auto {
   /* height: calc(100vh - 170px); */
   height: calc(100vh - var(--tableHeight));
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.auto_height {
   flex: 1;
   overflow: auto;
}
</style>
