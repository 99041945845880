<template lang="pug">
div.contactLens
  .auto(v-if='pagesGoods === 0' :style='{ "--tableHeight": tableHeight }')
    search-bar(:row='3' ref='searchBar')
      template(v-slot:content)
        el-form(:size='size' :inline="true" :model="userForm" label-width="100px" class="search")
          el-form-item(label="零售商全称")
            el-select(:size='size' v-model='userForm.emailFlRetailerId' clearable)
              el-option(
                v-for='item in selectList'
                :key='item.sysCiId'
                :value='item.sysCiId'
                :label='item.sysCiFullName'
              )
          el-form-item(label="开始时间")
            el-date-picker(:size='size' type="datetime" v-model='userForm.startTime'  value-format="yyyy-MM-dd HH:mm:ss")
          el-form-item(label="结束时间")
            el-date-picker(:size='size' type="datetime" v-model='userForm.endTime'  value-format="yyyy-MM-dd HH:mm:ss")
      template(v-slot:footer)
        el-button(:size='size' type='primary' icon='el-icon-search' @click='getList') 查询
        el-button(:size='size' icon='el-icon-delete' @click='reset') 清空
    ps-table.mt10.auto_height(
      height='100%'
      v-loading='tableLoading'
      ref='psTable'
      :header-cell-style="headerCellStyle"
      :size="size"
      :data='tableData'
      :columns='columns'
      :columns-props="columnsProps"
    )
      template(#解析状态="{ row = {} }") 
        span(:style="{ color: !row.emailFlStatus ? 'green' : 'red' }") {{ row.emailFlStatus === 0 ?'成功':'失败' }}
      template(#附件下载地址="{ row ={} }")
        el-button(type='text' :size='size'  @click='download(row.emailFlFileUrl)') 下载附件
      template(#操作="{ row ={} }")
        el-button(type='text' :size='size' icon='el-icon-position' @click='reSend(row.emailFlId)' v-if='row.emailFlStatus' :disabled='reSendLoading') 二次解析
        el-button(type='text' :size='size'  @click='viewGoos(row.emailFlOrderNum,row.emailFlRetailerId)' v-else) 查看商品
  pagination(v-if='pagesGoods === 0' :total='total' :current-page='page' :page-size='pageSize' @size-change='changeSize' @current-change='changePage')
  goodsView(v-if='pagesGoods === 1' @back='clickBack' :row='currentEditRow' ref='see')
</template>

<script>
import { timeFormat } from "@/utils/timeFormat"
import pagination from "@/components/assembly-pagination/index"
import { configMixin } from "./configMixin" // 配置类混入
import goodsView from "./components/goodsView"
export default {
   name: "contactLens",

   components: {
      pagination,
      goodsView
   },

   mixins: [configMixin],

   data() {
      return {
         reSendLoading: false, // 二次解析loading按钮
         tableLoading: false,
         total: 0,
         page: 1,
         pageSize: 10,
         userForm: {},
         selectList: [],
         pagesGoods: 0,
         currentEditRow: {}
      }
   },

   methods: {
      fTIME(val) {
         return timeFormat(val)
      },
      // 二次解析
      reSend(id) {
         this.reSendLoading = true
         this.$server.reSendEmail({ failLogId: id }).then(res => {
            if (res.ok) {
               this.getList()
               this.$message.success("二次解析成功")
               this.reSendLoading = false
            } else {
               this.getList()
               this.$message.error(res.msg)
               this.reSendLoading = false
            }
         })
      },
      viewGoos(emailFlOrderNum, emailFlRetailerId) {
         if (emailFlOrderNum == null || emailFlOrderNum == '') {
            this.$message.warning("订单号不能为空")
         } else {
            let data = {
               emailFlOrderNum: emailFlOrderNum,
               emailFlRetailerId: emailFlRetailerId
            }
            this.currentEditRow = data;
            this.pagesGoods = 1;
         }



      },
      reset() {
         this.userForm = {}
         this.page = 1
         this.getList()
      },

      getGHSList() {
         this.$server.getGHSList({ sysCiType: 3 }).then(res => {
            this.selectList = res.data
         })
      },

      changeSize(val) {
         this.page = 1
         this.pageSize = val
         this.getList()
      },

      changePage(val) {
         this.page = val
         this.getList()
      },

      getList() {
         this.tableLoading = true
         const { page, pageSize, userForm } = this
         this.$server
            .getEmailLog({ page, pageSize, ...userForm })
            .then(res => {
               this.tableData = res.data.records
               this.total = res.data.total
               this.tableLoading = false
            })
            .catch(_ => {
               this.tableLoading = false
            })
      },
      clickBack() {
         this.pagesGoods = 0
      },
      download(url) {

         if (url) {
            window.open(url)
         } else {
            this.$message.error("下载地址链接不能为空")
         }


      }
   },

   computed: {
      tableHeight() {
         let init = 170
         const { fastNav, isHeader } = this.$store.state
         fastNav === 1 ? init -= 31 : 0
         isHeader === 1 ? init -= 60 : 0
         console.log(init);
         return init + 'px'
      }
   },

   mounted() {
      this.pageSize = this.$store.state.initPageSize ? this.$store.state.initPageSize : 10;
      this.getList()
      this.getGHSList()
   },
}
</script>

<style scoped>
.auto {
   /* height: calc(100vh - 170px); */
   height: calc(100vh - var(--tableHeight));
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.auto_height {
   flex: 1;
   overflow: auto;
}
</style>
