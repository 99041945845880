import { render, staticRenderFns } from "./goodsView.vue?vue&type=template&id=0948d7f2&scoped=true&lang=pug&"
import script from "./goodsView.vue?vue&type=script&lang=js&"
export * from "./goodsView.vue?vue&type=script&lang=js&"
import style0 from "./goodsView.vue?vue&type=style&index=0&id=0948d7f2&prod&lang=scss&"
import style1 from "./goodsView.vue?vue&type=style&index=1&id=0948d7f2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0948d7f2",
  null
  
)

export default component.exports