export const configMixin = {
  computed: {
    // 此 size 从 vuex 中读取，用于全局主题样式大小
    size() {
      return this.$store.state.size
    },
  },

  methods: {
    formatterFailText(val) {
      try {
        const formatter = [{
            msg: 'java.lang.IllegalArgumentException:',
            fn: () => {
              return this.val.replace(this.msg, '')
            }
          },
          {
            msg: 'java.lang.Exception:',
            fn: () => {
              return this.val.replace(this.msg, '')
            }
          },
          {
            msg: 'java.lang.NullPointerException',
            fn: () => '未知错误'
          },
        ]
        formatter.map(item => {
          if (val.includes(item.msg)) {
            return val.replace(item.msg, '')
          }
        })
      } catch {}
    }
  },

  data() {
    return {
      // 组件表格行 props 参数
      columnsProps: {
        align: 'center'
      },

      // 表头颜色
      headerCellStyle: {
        background: '#f5f7fa',
        color: '#515a6e',
        fontWeight: '900'
      },

      //简单 table 数据
      tableData: [],

      // 表格的配置项
      columns: [{
          prop: "emailFlCreateTime",
          label: "时间",
          formatter: row => this.fTIME(row.emailFlCreateTime)
        },
        {
          prop: "emailFlFileName",
          label: "邮件名称"
        },
        {
          prop: "emailFlSupplierName",
          label: "发货厂家名称"
        },
        {
          prop: "emailFlSupplierEmail",
          label: "发货厂家邮箱"
        },
        {
          prop: "emailFlRetailerName",
          label: "接收厂家名称"
        },
        {
          prop: "emailFlRetailerEmail",
          label: "接收厂家邮箱"
        },
        {
          prop: "emailFlStatus",
          label: "解析状态",
          custom: true,
          width: 100
        },
        {
          prop: "emailFlMessage",
          label: "失败原因"
        },
        {
          prop: "emailFlFileUrl",
          label: "附件下载地址",
          custom: true
        },
        {
          prop: "g",
          label: "操作",
          custom: true
        },
      ],
    }
  },
}