export const configMixin = {
  computed: {
    // 此 size 从 vuex 中读取，用于全局主题样式大小
    size() {
      return this.$store.state.size
    },
  },

  data() {
    return {
      // 组件表格行 props 参数
      columnsProps: {
        align: 'center'
      },

      // 表头颜色
      headerCellStyle: {
        background: '#f5f7fa',
        color: '#515a6e',
        fontWeight: '900'
      },

      //简单 table 数据
      tableData: [],

      // 表格的配置项
      columns: [
        { prop: "emailEgGoodsSku", label: "商品SKU" },
        { prop: "emailEgGoodsInfoName", label: "商品名称" },
        { prop: "emailEgSph", label: "球镜" },
        { prop: "emailEgCyl", label: "柱镜" },
        { prop: "emailEgColorNum", label: "色号" },
        { prop: "emailEgRetailerName", label: "零售商" },
        { prop: "emailEgSupplierName", label: "供应商" },
        { prop: "emailEgProductType", label: "商品类型" },
        { prop: "emailEgDeliveryNum", label: "发货数量" },
        // { prop: "emailEgGoodsSku", label: "商品SKU" },
        // { prop: "emailEgGoodsSku", label: "商品SKU" },
        // { prop: "emailEgGoodsSku", label: "商品SKU" },
        // { prop: "emailEgGoodsSku", label: "商品SKU" },
        // { prop: "emailEgGoodsSku", label: "商品SKU" },
        // { prop: "emailEgGoodsSku", label: "商品SKU" },
      ],
    }
  },
}
